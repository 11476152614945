@use '../style/margins' as m;
@use '../style/breakpoints' as b;

.row {
  align-items: stretch;

  .first-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    .arrow {
      float: right;
      height: 55px;
      width: 55px;
      margin-left: m.$M3;
    }
  }

  @media only screen and (max-width: calc(b.$md - 1px)) {
    .first-col {
      flex-direction: row;
      align-items: center;
    }
  }
}

.employee-row {
  row-gap: m.$M9;
}
