@use '../style/colors' as c;
@use '../style/margins' as m;
@use '../style/breakpoints' as b;

.header {
  color: c.$yellow;
}

.list {
  display: flex;
  flex-direction: column;
  gap: m.$M3;
}

@keyframes pulse {
  0%,
  100% {
    background-color: rgba($color: c.$gray, $alpha: 0.4);
  }
  50% {
    background-color: rgba($color: c.$light-varm, $alpha: 0.3);
  }
}

.assignment-skeleton {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: m.$M3;

  background-color: c.$gray;
  animation: pulse 1s infinite;
  border-radius: 22px;

  div {
    opacity: 0;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .arrow {
    display: flex;

    img {
      transform: rotate(-135deg);
    }
  }
}

@media only screen and (min-width: b.$md) {
  .arrow {
    img {
      height: 50px;
    }
  }
}

@media only screen and (max-width: calc(b.$md - 1px)) {
  .arrow {
    img {
      height: 25px;
    }
  }
}
