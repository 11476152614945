@use '../style/colors' as c;
@use '../style/margins' as m;
@use '../style/breakpoints' as b;

.link {
  text-decoration: none;
  color: c.$white;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: m.$M3;

  background-color: c.$gray;
  border-radius: 22px;

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .arrow {
    display: flex;

    img {
      transform: rotate(-135deg);
    }
  }

  @media only screen and (min-width: b.$md) {
    .arrow {
      img {
        height: 50px;
      }
    }
  }

  @media only screen and (max-width: calc(b.$md - 1px)) {
    .arrow {
      img {
        height: 25px;
      }
    }
  }
}
