@use '../style/colors' as c;
@use '../style/margins' as m;

//Other components are dependent on these!!
$small-top-bottom-padding: m.$M3;
$default-top-bottom-padding: m.$M12;
$large-top-bottom-padding: m.$M18;

$xl-top-bottom-padding: m.$M24;

.section {
  overflow: hidden;

  &.small-padding-top {
    padding-top: $small-top-bottom-padding;
  }
  &.default-padding-top {
    padding-top: $default-top-bottom-padding;
  }
  &.large-padding-top {
    padding-top: $large-top-bottom-padding;
  }
  &.xl-padding-top {
    padding-top: $xl-top-bottom-padding;
  }

  &.small-padding-bottom {
    padding-bottom: $small-top-bottom-padding;
  }
  &.default-padding-bottom {
    padding-bottom: $default-top-bottom-padding;
  }
  &.large-padding-bottom {
    padding-bottom: $large-top-bottom-padding;
  }
  &.xl-padding-bottom {
    padding-bottom: $xl-top-bottom-padding;
  }

  &.bg-black {
    background-color: c.$black;
  }
  &.bg-yellow {
    background-color: c.$yellow;
  }
  &.bg-gray {
    background-color: c.$gray;
  }
  &.bg-true-black {
    background-color: c.$true-black;
  }
  &.bg-light-varm {
    background-color: c.$light-varm;
  }

  &.c-white {
    color: c.$white;
  }
  &.c-black {
    color: c.$black;
  }

  &.r-top {
    position: relative;
    margin-top: -35px;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
  }
  &.r-bottom {
    position: relative;
    margin-bottom: -35px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
  }
}
