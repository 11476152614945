@use '../style/colors' as c;
@use '../style/margins' as m;
@use '../style/breakpoints' as b;

.container {
  position: relative;
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #00000070;
    color: #fff;
    overflow: hidden;

    padding: 0 m.$M6;
    text-align: center;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: c.$black;
    padding: 0 m.$M9;

    &.bg-yellow {
      background-color: c.$yellow;
    }
    &.bg-light-varm {
      background-color: c.$light-varm;
    }
    &.bg-black {
      background-color: c.$black;
    }
  }
}

//Mobile
@media only screen and (max-width: calc(b.$lg - 1px)) {
  .container {
    img {
      height: 300px;
    }
  }

  .desktop-only {
    display: none !important;
  }
}

//Desktop
@media only screen and (min-width: b.$lg) {
  .mobile-only {
    display: none !important;
  }
}
