@use '../style/margins' as m;
@use '../style/breakpoints' as b;

.text-arrow-row {
  flex-wrap: nowrap;
  align-items: center;
}

.arrow {
  height: 100px;
  width: 100px;
  margin-left: 48px;

  &.black {
    transform: rotate(45deg);
  }
}

.center {
  text-align: center;
}

.values {
  display: flex;
  .value {
    min-width: 33%;
    flex-grow: 1;
  }
}

.quote {
  display: flex;
  gap: m.$M3;
  align-items: center;

  .quote-img {
    height: 50px;
  }

  h5 {
    float: right;
  }
}

.quoter {
  display: flex;
  justify-content: flex-end;
}

//Mobile
@media only screen and (max-width: calc(b.$lg - 1px)) {
  .values {
    flex-direction: column;
  }

  .values-illustration {
    width: 80vw;
  }
}

//Desktop
@media only screen and (min-width: b.$lg) {
  .values {
    .value {
      height: 500px;
    }
  }

  .values-illustration {
    width: 800px;
  }
}
