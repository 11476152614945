@use '../style/colors' as c;

.image {
  width: 100%;
  height: 450px;
  border-radius: 25px;
  object-fit: cover;
}

.name {
  text-transform: uppercase;
}

.email-container {
  display: flex;
  align-items: center;
  margin-top: 6px;
  
  img {
    margin-right: 8px;
  }
  
  a {
    color: c.$black;
  }
}
