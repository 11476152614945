@use '../style/breakpoints' as b;

.row-extension {
  padding-right: 0;
}

.image-container {
  flex-direction: column;
  padding-right: 0;
}

.black-arrow {
  transform: rotate(90deg);
  width: 27px;
  height: 27px;
}

.yellow-arrow {
  display: none;
}

@media only screen and (min-width: b.$md) {
  .image-container {
    flex-direction: unset;
    padding-right: unset;
  }

  .yellow-arrow {
    width: 47px;
    height: 47px;
    display: unset;
    transform: rotate(-135deg);
  }

  .black-arrow {
    display: none;
  }
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}
