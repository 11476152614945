@use '../style/colors' as c;
@use '../style/margins' as m;
@use '../style/breakpoints' as b;

.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;

  .image-container {
    height: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .content-container-desktop {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: stretch;
    height: 100%;

    .col {
      position: relative;

      &.image-left {
        margin-left: auto;
      }
      &.image-right {
        margin-right: auto;
      }

      .content {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        z-index: 1;

        &.c-white {
          color: c.$white;
        }
        &.c-black {
          color: c.$black;
        }
      }

      .content-background {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100vw;
        z-index: 0;

        &.image-left {
          left: calc((m.$M6 - 12px) * -1);
        }
        &.image-right {
          right: calc((m.$M6 - 12px) * -1);
        }

        &.bg-black {
          background-color: c.$black;
        }
        &.bg-yellow {
          background-color: c.$yellow;
        }
        &.bg-light-varm {
          background-color: c.$light-varm;
        }
      }
    }
  }
}

@media only screen and (min-width: b.$md) {
  .container {
    &.image-right {
      flex-direction: row-reverse;
    }

    .image-container {
      width: 80%;
    }

    .content-container-mobile {
      display: none;
    }
  }
}

@media only screen and (max-width: calc(b.$md - 1px)) {
  .container {
    flex-direction: column;

    .image-container {
      width: 100%;
    }

    .content-container-desktop {
      display: none;
    }
  }
}
