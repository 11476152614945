@use '../style/colors' as c;
@use '../style/margins' as m;

.header {
  color: c.$yellow;
}

.form-row {
  row-gap: m.$M3;
}

.checkbox-row {
  justify-content: flex-end;
}

.button-row {
  justify-content: flex-end;
}

.submit-error-row {
  justify-content: flex-end;
  margin-top: m.$M1;
  color: c.$red;
}

.submit-successful-row {
  justify-content: flex-end;
  margin-top: m.$M1;
  color: c.$green;
}
