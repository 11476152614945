@use './style/fonts';
@use './style/layout';

* {
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
