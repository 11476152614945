@use '../style/colors' as c;
@use '../style/margins' as m;
@use '../style/sizes' as s;

.navbar {
  position: fixed;
  z-index: 99;

  top: 0;
  width: 100%;

  background-color: transparent;
  border-bottom: 1px solid c.$white;

  justify-content: space-between;
  align-items: center;

  .navbar-content {
    height: 100%;
    align-items: center;
    justify-content: space-between;

    .mobile-img {
      height: s.$logo-height-small;
    }

    nav {
      display: flex;
      gap: 24px;
      padding: 8px 16px;
      border: 1px solid c.$white;
      border-radius: 24px;

      a {
        text-decoration: none;
        color: c.$white;

        &.active {
          color: c.$yellow;
          border-bottom: 1px solid c.$yellow;
        }
      }
    }
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: m.$M2;

    a {
      color: c.$white;
      text-decoration: none;

      &.active {
        color: c.$yellow;
        border-bottom: 1px solid c.$yellow;
      }
    }
  }
}
