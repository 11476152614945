@use '../style/margins' as m;
@use '../style/colors' as c;

.container {
  //flex-grow makes it bigger, but without this every instance
  //gets different widths depending on their content
  width: 0px;

  flex-grow: 1;
  padding: m.$M3;
}

.mobile-column {
  padding-top: m.$M3;
  padding-bottom: m.$M3;
}

.header {
  text-transform: uppercase;
}

li {
  margin-bottom: m.$M1;
}

.b-black {
  background-color: c.$black;
}
.b-white {
  background-color: c.$white;
}
.b-yellow {
  background-color: c.$yellow;
}

.c-black {
  color: c.$black;
}
.c-white {
  color: c.$white;
}
.c-yellow {
  color: c.$yellow;
}
