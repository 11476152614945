$yellow: #d5ff60;
$green: #00ec64;
$turquoise: #bbffe6;
$red: #ff6a4a;
$blue: #3700ff;
$black: #302e2e;
$gray: #494848;
$sand: #c6c69d;
$light-cold: #d5d5d2;
$light-varm: #e9e7da;
$white: #fff;
$true-black: #000;

$button-hover-dark: #00000010;
$button-hover-light: #ffffff10;
