@use './margins' as m;
@use './breakpoints' as b;

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

.row.center {
  justify-content: center;
}

.row.space-between {
  justify-content: space-between;
}

/* Extra small devices (phones, 599px and down) */
@media only screen and (max-width: calc(b.$sm - 1px)) {
  .row {
    padding: 0 10px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: b.$sm) {
  .row {
    padding: 0 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: b.$md) {
  .row {
    padding: 0 30px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: b.$lg) {
  .row {
    padding: 0 50px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: b.$xl) {
  .row {
    padding: 0 150px;
  }
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  padding: 0 12px;
  display: block;
}

.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: m.$M1;
}
.mb-2 {
  margin-bottom: m.$M2;
}
.mb-3 {
  margin-bottom: m.$M3;
}
.mb-4 {
  margin-bottom: m.$M4;
}
.mb-5 {
  margin-bottom: m.$M5;
}
.mb-6 {
  margin-bottom: m.$M6;
}
.mb-9 {
  margin-bottom: m.$M9;
}
.mb-12 {
  margin-bottom: m.$M12;
}
.mb-24 {
  margin-bottom: m.$M24;
}

@media only screen and (min-width: b.$sm) {
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    padding: 0 12px;
    display: block;
  }

  .col-sm-1 {
    width: 8.33%;
  }
  .col-sm-2 {
    width: 16.66%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.33%;
  }
  .col-sm-5 {
    width: 41.66%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.33%;
  }
  .col-sm-8 {
    width: 66.66%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.33%;
  }
  .col-sm-11 {
    width: 91.66%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .d-sm-none {
    display: none;
  }
  .d-sm-block {
    display: block;
  }
  .d-sm-flex {
    display: flex;
  }
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .mb-sm-1 {
    margin-bottom: m.$M1;
  }
  .mb-sm-2 {
    margin-bottom: m.$M2;
  }
  .mb-sm-3 {
    margin-bottom: m.$M3;
  }
  .mb-sm-4 {
    margin-bottom: m.$M4;
  }
  .mb-sm-5 {
    margin-bottom: m.$M5;
  }
  .mb-sm-6 {
    margin-bottom: m.$M6;
  }
  .mb-sm-9 {
    margin-bottom: m.$M9;
  }
  .mb-sm-12 {
    margin-bottom: m.$M12;
  }
  .mb-sm-24 {
    margin-bottom: m.$M24;
  }
}

@media only screen and (min-width: b.$md) {
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    padding: 0 12px;
    display: block;
  }

  .col-md-1 {
    width: 8.33%;
  }
  .col-md-2 {
    width: 16.66%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33%;
  }
  .col-md-5 {
    width: 41.66%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.33%;
  }
  .col-md-8 {
    width: 66.66%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.33%;
  }
  .col-md-11 {
    width: 91.66%;
  }
  .col-md-12 {
    width: 100%;
  }
  .d-md-none {
    display: none;
  }
  .d-md-block {
    display: block;
  }
  .d-md-flex {
    display: flex;
  }
  .mb-md-0 {
    margin-bottom: 0;
  }
  .mb-md-1 {
    margin-bottom: m.$M1;
  }
  .mb-md-2 {
    margin-bottom: m.$M2;
  }
  .mb-md-3 {
    margin-bottom: m.$M3;
  }
  .mb-md-4 {
    margin-bottom: m.$M4;
  }
  .mb-md-5 {
    margin-bottom: m.$M5;
  }
  .mb-md-6 {
    margin-bottom: m.$M6;
  }
  .mb-md-9 {
    margin-bottom: m.$M9;
  }
  .mb-md-12 {
    margin-bottom: m.$M12;
  }
  .mb-md-24 {
    margin-bottom: m.$M24;
  }
}

@media only screen and (min-width: b.$lg) {
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    padding: 0 12px;
    display: block;
  }

  .col-lg-1 {
    width: 8.33%;
  }
  .col-lg-2 {
    width: 16.66%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.33%;
  }
  .col-lg-5 {
    width: 41.66%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.33%;
  }
  .col-lg-8 {
    width: 66.66%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.33%;
  }
  .col-lg-11 {
    width: 91.66%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .d-lg-none {
    display: none;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-flex {
    display: flex;
  }
  .mb-lg-0 {
    margin-bottom: 0;
  }
  .mb-lg-1 {
    margin-bottom: m.$M1;
  }
  .mb-lg-2 {
    margin-bottom: m.$M2;
  }
  .mb-lg-3 {
    margin-bottom: m.$M3;
  }
  .mb-lg-4 {
    margin-bottom: m.$M4;
  }
  .mb-lg-5 {
    margin-bottom: m.$M5;
  }
  .mb-lg-6 {
    margin-bottom: m.$M6;
  }
  .mb-lg-9 {
    margin-bottom: m.$M9;
  }
  .mb-lg-12 {
    margin-bottom: m.$M12;
  }
  .mb-lg-24 {
    margin-bottom: m.$M24;
  }
}

@media only screen and (min-width: b.$xl) {
  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12 {
    padding: 0 12px;
    display: block;
  }

  .col-xl-1 {
    width: 8.33%;
  }
  .col-xl-2 {
    width: 16.66%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.33%;
  }
  .col-xl-5 {
    width: 41.66%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.33%;
  }
  .col-xl-8 {
    width: 66.66%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.33%;
  }
  .col-xl-11 {
    width: 91.66%;
  }
  .col-xl-12 {
    width: 100%;
  }
  .d-xl-none {
    display: none;
  }
  .d-xl-block {
    display: block;
  }
  .d-xl-flex {
    display: flex;
  }
  .mb-xl-0 {
    margin-bottom: 0;
  }
  .mb-xl-1 {
    margin-bottom: m.$M1;
  }
  .mb-xl-2 {
    margin-bottom: m.$M2;
  }
  .mb-xl-3 {
    margin-bottom: m.$M3;
  }
  .mb-xl-4 {
    margin-bottom: m.$M4;
  }
  .mb-xl-5 {
    margin-bottom: m.$M5;
  }
  .mb-xl-6 {
    margin-bottom: m.$M6;
  }
  .mb-xl-9 {
    margin-bottom: m.$M9;
  }
  .mb-xl-12 {
    margin-bottom: m.$M12;
  }
  .mb-xl-24 {
    margin-bottom: m.$M24;
  }
}

@media only screen and (min-width: b.$xxxl) {
  .col-xxl,
  .col-xxl-1,
  .col-xxl-2,
  .col-xxl-3,
  .col-xxl-4,
  .col-xxl-5,
  .col-xxl-6,
  .col-xxl-7,
  .col-xxl-8,
  .col-xxl-9,
  .col-xxl-10,
  .col-xxl-11,
  .col-xxl-12 {
    padding: 0 12px;
    display: block;
  }

  .col-xxl-1 {
    width: 8.33%;
  }
  .col-xxl-2 {
    width: 16.66%;
  }
  .col-xxl-3 {
    width: 25%;
  }
  .col-xxl-4 {
    width: 33.33%;
  }
  .col-xxl-5 {
    width: 41.66%;
  }
  .col-xxl-6 {
    width: 50%;
  }
  .col-xxl-7 {
    width: 58.33%;
  }
  .col-xxl-8 {
    width: 66.66%;
  }
  .col-xxl-9 {
    width: 75%;
  }
  .col-xxl-10 {
    width: 83.33%;
  }
  .col-xxl-11 {
    width: 91.66%;
  }
  .col-xxl-12 {
    width: 100%;
  }
  .d-xxl-none {
    display: none;
  }
  .d-xxl-block {
    display: block;
  }
  .d-xxl-flex {
    display: flex;
  }
  .mb-xxl-0 {
    margin-bottom: 0;
  }
  .mb-xxl-1 {
    margin-bottom: m.$M1;
  }
  .mb-xxl-2 {
    margin-bottom: m.$M2;
  }
  .mb-xxl-3 {
    margin-bottom: m.$M3;
  }
  .mb-xxl-4 {
    margin-bottom: m.$M4;
  }
  .mb-xxl-5 {
    margin-bottom: m.$M5;
  }
  .mb-xxl-6 {
    margin-bottom: m.$M6;
  }
  .mb-xxl-9 {
    margin-bottom: m.$M9;
  }
  .mb-xxl-12 {
    margin-bottom: m.$M12;
  }
  .mb-xxl-24 {
    margin-bottom: m.$M24;
  }
}
