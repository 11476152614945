@use '../style/colors' as c;
@use '../style/margins' as m;
@use '../style/breakpoints' as b;

.fallback {
  height: 100vh;
  background-color: c.$black;
  color: c.$white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  display: flex;
  align-items: center;
  gap: m.$M3;

  .location,
  .deadline {
    display: flex;
    align-items: center;
    gap: m.$M1;
  }
}

.button-text {
  margin-right: m.$M1;
}

.card {
  background-color: c.$gray;
  border-radius: 25px;
  padding: m.$M6;

  h1 {
    margin-top: m.$M5;
    margin-bottom: m.$M2;
  }

  h2 {
    margin-top: m.$M5;
    margin-bottom: m.$M2;
  }

  h3 {
    margin-top: m.$M5;
    margin-bottom: m.$M2;
  }

  h4 {
    margin-top: m.$M5;
    margin-bottom: m.$M2;
  }

  h5 {
    margin-top: m.$M5;
    margin-bottom: m.$M2;
  }

  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.apply {
  display: flex;
  flex-direction: row-reverse;
}

//Mobile
@media only screen and (max-width: calc(b.$lg - 1px)) {
  .card {
    padding: m.$M4;
  }

  .apply {
    padding-top: m.$M4;
  }
}
