@use '../style/margins' as m;
@use '../style/colors' as c;
@use '../style/breakpoints' as b;

.banner {
  position: relative;
  width: 100%;
  height: 100vh;

  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .textContainer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100px;
  }

  .text {
    color: c.$yellow;
    margin-left: auto;
  }
}

.part2 {
  .arrow {
    img {
      height: 75px;
      width: 75px;
      float: right;
    }
  }
}

.background-image {
  background-image: url('../assets/images/Startsida_2.webp');
  background-size: cover;
  background-position: top 0px left 50%;
}

.image-text-part {
  text-align: center;
  height: 600px;

  padding: m.$M6 0;
}

.link {
  display: flex;
  justify-content: center;
}

.part5 {
  display: flex;

  .component {
    min-width: 33%;
    flex-grow: 1;
  }
}

//Mobile
@media only screen and (max-width: calc(b.$lg - 1px)) {
  .part5 {
    flex-direction: column;
  }

  .image-mobile {
    display: unset;
  }

  .image-desktop {
    display: none;
  }
}

//Desktop
@media only screen and (min-width: b.$lg) {
  .part5 {
    .component {
      height: 500px;
    }
  }

  .image-mobile {
    display: none;
  }

  .image-desktop {
    display: unset;
  }
}
