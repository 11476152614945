@use '../style/colors' as c;

.banner {
  position: relative;
  width: 100%;
  height: 80vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.h-c-white {
  color: c.$white;
}
.h-c-black {
  color: c.$black;
}
.h-c-yellow {
  color: c.$yellow;
}
