@use '../style/colors' as c;
@use '../style/margins' as m;

.container {
  display: flex;
  flex-direction: column;

  &.invalid {
    input {
      border: 1px solid c.$red;
    }
  }

  label {
    margin-bottom: 4px;
  }

  input {
    padding: m.$M2 m.$M3;
    color: c.$white;
    background-color: c.$gray;
    border: none;
    border-radius: 22px;
  }

  .error-message {
    color: c.$red;
    margin-top: m.$M1;
  }
}
