@use '../../style/colors' as c;
@use '../../style/breakpoints' as b;

.circle-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;

  .circle {
    background-color: c.$gray;
    border: 1px solid c.$gray;
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    margin-left: 12px;
    width: 16px;
  }

  .active-circle {
    background-color: c.$yellow;
  }

  @media only screen and (max-width: calc(b.$md - 1px)) {
    margin-top: 42px;
  }
}
