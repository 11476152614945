@use '../style/colors' as c;
@use '../style/margins' as m;

.container {
  display: flex;
  flex-direction: column;

  &.invalid {
    .file-input-label {
      border: 1px solid c.$red;
    }
  }

  label {
    margin-bottom: 4px;
  }

  .error-message {
    color: c.$red;
    margin-top: m.$M1;
  }

  .file-input-label {
    background-color: c.$gray;
    border: none;
    border-radius: 22px;
    color: c.$white;
    cursor: pointer;
    padding: m.$M2 m.$M3;

    img {
      width: 24px;
    }

    span {
      margin-left: 8px;
    }

    input {
      display: none;
    }
  }
}
