@use '../../style/colors' as c;
@use '../../style/margins' as m;
@use '../../style/breakpoints' as b;

.col {
  position: relative;

  .arrow {
    position: absolute;
    right: 0;
    top: -50px;
  }
}

.lists {
  display: flex;
  gap: 1px;
  background-color: c.$black;
}

//Mobile
@media only screen and (max-width: calc(b.$md - 1px)) {
  .lists {
    flex-direction: column;
  }
}

//Desktop
@media only screen and (min-width: b.$md) {
  .lists {
    flex-direction: row;
  }
}
