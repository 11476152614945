@use '../style/colors' as c;
@use '../style/margins' as m;

.container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  a {
    color: c.$white;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: -35px;
  height: 25px;
  width: 25px;
  background-color: c.$gray;
}

.container input:checked ~ .checkmark {
  background-color: c.$yellow;
}

.container input:focus ~ .checkmark {
  outline: 2px solid c.$white;
}

.container.error .checkmark {
  outline: 2px solid c.$red;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 8px;
  top: 1px;
  width: 10px;
  height: 19px;
  border: solid c.$black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
