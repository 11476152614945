@use '../style/colors' as c;

.text-arrow-row {
  flex-wrap: nowrap;
}

.text-col {
  flex-shrink: 2;
}

.arrow {
  width: 100px;
  height: 100px;
  transform: rotate(45deg);
}

.course-button {
  float: right;
}
