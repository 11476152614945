@use '../style/colors' as c;

.textContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 75px;
}

.text {
  &.pos-left {
    margin-right: auto;
  }
  &.pos-right {
    margin-left: auto;
  }

  &.c-white {
    color: c.$white;
  }
  &.c-black {
    color: c.$black;
  }
}
