$M1: 8px;
$M2: 16px;
$M3: 24px;
$M4: 32px;
$M5: 40px;
$M6: 48px;
$M9: 72px;
$M12: 96px;
$M18: 144px;
$M24: 192px;
