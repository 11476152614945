@use '../style/colors' as c;
@use '../style/margins' as m;

.button {
  display: flex;
  align-items: center;

  text-decoration: none;
  text-transform: uppercase;

  &.c-black {
    color: c.$black;
  }
  &.c-white {
    color: c.$white;
  }

  .container {
    display: flex;
    align-items: center;

    .icon {
      margin-top: 6px;
      align-self: flex-start;
      transform: rotate(-90deg);
      width: 14px;
      height: 14px;
      margin-right: m.$M1;
    }

    .text-container {
      align-self: start;
      position: relative;
      display: flex;
      flex-direction: column;
      width: fit-content;

      .line {
        position: absolute;
        bottom: 0;

        &.c-white {
          border-top: 1px solid c.$white;
        }
        &.c-black {
          border-top: 1px solid c.$black;
        }
      }
    }
  }
}
