@use '../style/colors' as c;
@use '../style/margins' as m;
@use '../style/breakpoints' as b;

.root {
  position: relative;
}

.container {
  position: absolute;
  bottom: 0;
  border: 1px solid;
  border-bottom: none;
  border-radius: 25px 25px 0 0;
  padding: m.$M2 m.$M3;
  width: fit-content;

  &.b-white {
    border-color: c.$white;
  }
  &.b-black {
    border-color: c.$black;
  }
  &.b-yellow {
    border-color: c.$yellow;
  }
}

@media only screen and (min-width: b.$md) {
  .container {
    margin-left: calc(m.$M3 * -1);
  }
}

.text {
  text-wrap: nowrap;
  white-space: nowrap; // Needed for Safari

  &.c-white {
    color: c.$white;
  }
  &.c-black {
    color: c.$black;
  }
  &.c-yellow {
    color: c.$yellow;
  }
}
