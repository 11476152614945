@use '../style/colors' as c;
@use '../style/margins' as m;

.text {
  position: absolute;
  bottom: 0;
  animation: hideText 4s linear infinite;
}

.loader {
  position: relative;
  height: 200px;
  width: 200px;
  border-bottom: 3px solid c.$yellow;
  box-sizing: border-box;
  animation: drawLine 4s linear infinite;
  color: c.$yellow;
}
.loader:before {
  content: '';
  position: absolute;
  left: calc(100% + 14px);
  bottom: -6px;
  width: 16px;
  height: 100px;
  border-radius: 20px 20px 50px 50px;
  background-repeat: no-repeat;
  background-image: linear-gradient(c.$yellow 6px, transparent 0),
    linear-gradient(45deg, rgba(0, 0, 0, 0.02) 49%, white 51%),
    linear-gradient(315deg, rgba(0, 0, 0, 0.02) 49%, white 51%),
    linear-gradient(
      to bottom,
      #ffffff 10%,
      c.$yellow 10%,
      c.$yellow 90%,
      #ffffff 90%
    );
  background-size: 3px 3px, 8px 8px, 8px 8px, 16px 88px;
  background-position: center bottom, left 88px, right 88px, left top;
  transform: rotate(25deg);
  animation: pencilRot 4s linear infinite;
}

@keyframes hideText {
  0%,
  6%,
  85%,
  100% {
    opacity: 0;
  }
  25%,
  30%,
  50%,
  75% {
    opacity: 1;
  }
}

@keyframes drawLine {
  0%,
  100% {
    width: 0px;
  }
  45%,
  55% {
    width: 200px;
  }
}

@keyframes pencilRot {
  0%,
  45% {
    bottom: -6px;
    left: calc(100% + 14px);
    transform: rotate(25deg);
  }
  55%,
  100% {
    bottom: -12px;
    left: calc(100% + 16px);
    transform: rotate(220deg);
  }
}
