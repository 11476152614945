@use '../style/colors' as c;
@use '../style/margins' as m;

.desktop-root {
  position: relative;
  width: 100%;

  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    transform: translateY(-50%);

    .floating-box {
      padding: m.$M3 m.$M6;
      border-radius: 26px;
      background-color: c.$yellow;
    }
  }
}
