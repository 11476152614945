@use '../style/margins' as m;
@use '../style/colors' as c;
@use '../style/breakpoints' as b;

.container {
  background-color: c.$black;
  color: c.$white;
  height: 3840px;
  position: relative;
  width: 600px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .background-image {
    width: 100%;
    position: relative;
    z-index: 0;
  }

  .logo-image {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
  }

  .logo-name-image {
    align-self: center;
    margin-top: 284px;
    position: relative;
    width: 80%;
  }

  h1 {
    position: relative;
    z-index: 1;
    text-align: center;
    padding-top: 200px;
    width: 80%;
    align-self: center;
    font-size: 80px;
    line-height: 120px;
  }

  .about-us {
    align-self: center;
    margin-top: 18px;
    text-align: center;
    width: 80%;
    font-size: 38px;
    line-height: 40px;
  }

  /* Carousel */
  .carousel-container {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-top: 500px;
    width: 100%;
  }

  .carousel {
    align-self: center;
    position: relative;
    width: 90%;
    height: 300px;
    overflow: hidden;
    background-color: #cdcdcd;
  }

  .carousel:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .carousel:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .carousel-item .slide-image {
    width: 100%;
    max-height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .carousel-item {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100%;
    border: none;
    top: 0;
    left: 100%;
  }

  .carousel-item.active {
    left: 0;
    transition: all 0.3s ease-out;
  }

  .carousel-item div {
    height: 100%;
  }

  .about-us-2 {
    align-self: center;
    font-size: 1.2rem;
    position: absolute;
    bottom: 600px;
    text-align: center;
    width: 80%;
  }

  /* QR-Code */
  .qr-code {
    align-self: center;
    bottom: 12px;
    border-radius: 8px;
    position: absolute;
    width: 94%;
  }
}
