@use '../style/colors' as c;

.button {
  cursor: pointer;

  display: flex;
  align-items: center;
  background-color: transparent;

  height: 32px;
  width: fit-content;
  padding: 0 15px;

  border: 1px solid c.$black;
  border-radius: 25px;

  &.disabled {
    background-color: transparent !important;
    cursor: default;
    border-color: c.$gray !important;
    color: c.$gray !important;
  }

  &.c-black {
    border-color: c.$black;
    color: c.$black;

    &:hover {
      background-color: c.$button-hover-dark;
    }
  }

  &.c-white {
    border-color: c.$white;
    color: c.$white;

    &:hover {
      background-color: c.$button-hover-light;
    }
  }
}
