@use '../../style/margins' as m;
@use '../../style/colors' as c;

.row {
  position: relative;
  align-items: flex-end;
  flex-wrap: nowrap;

  &.img-pos-right {
    flex-direction: row;
  }
  &.img-pos-left {
    flex-direction: row-reverse;
  }
}

.text-col {
  position: relative;
  margin-bottom: m.$M6;
  padding-top: m.$M6;
  padding-bottom: m.$M6;
}

.text {
  position: relative;
  z-index: 1;
}

.quote {
  display: flex;
  align-items: center;

  img {
    margin-right: m.$M2;
  }
}

.desktop {
  .image {
    position: relative;
    z-index: 1;
    height: 400px;
    max-width: 100%;
    object-fit: cover;
    border-radius: 25px;
  }

  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 90vw;
    z-index: 0;

    background-color: c.$yellow;

    &.justify-pos-right {
      right: -100px;
    }
    &.justify-pos-left {
      left: -100px;
    }
  }
}

.mobile {
  &.row {
    flex-wrap: wrap;
    row-gap: m.$M1;
  }

  .text-container {
    position: relative;
    z-index: 1;
  }

  .image-col {
    &.img-pos-right {
      margin-left: auto;
    }
    &.img-pos-left {
      margin-right: auto;
    }
  }

  .image {
    position: relative;
    z-index: 2;
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    object-position: 100% 0%;
    border-radius: 25px;
  }

  .background {
    position: absolute;
    top: calc(m.$M3 * -1);
    bottom: calc(m.$M3 * -1);
    width: 110vw;
    z-index: 1;
    background-color: c.$yellow;

    &.img-pos-right {
      border-radius: 0 25px 25px 0;
      right: 0;
    }
    &.img-pos-left {
      border-radius: 25px 0 0 25px;
      left: 0;
    }
  }
}
