@use '../style/colors' as c;
@use '../style/margins' as m;
@use '../style/breakpoints' as b;

.text-arrow-row {
  flex-direction: row-reverse;
}

.yellow-header {
  color: c.$yellow;
}

.center {
  text-align: center;
}

.arrow {
  width: 106px;
  height: 106px;
  transform: rotate(-90deg);
  margin-right: m.$M6;
}

.link {
  display: flex;
  justify-content: center;
}

// Work at iceberry section
.work-at-iceberry-section {
  padding-top: m.$M12;
  padding-bottom: m.$M12;

  background-color: c.$light-varm;
  color: c.$black;
}

@media only screen and (min-width: b.$md) {
  .work-at-iceberry-section {
    background-color: c.$black;
    color: c.$white;

    h4 {
      text-align: center;
      margin-bottom: m.$M9;
    }
  }

  .work-at-iceberry-sub-section {
    background-color: c.$gray;
    border: 1px solid c.$yellow;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    padding-top: m.$M12;
    padding-bottom: m.$M12;
  }
}
