@use './breakpoints' as b;

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/avenir_roman_12.otf') format('opentype');
  font-style: normal;
}

.icon {
  width: 24px;
  height: 24px;

  &.white {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(243deg)
      brightness(101%) contrast(102%);
  }
  &.black {
    filter: invert(12%) sepia(9%) saturate(215%) hue-rotate(314deg)
      brightness(96%) contrast(84%);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Avenir', 'Arial';
  font-style: normal;
  letter-spacing: 1px;
}

.text,
.small-text,
.large-text,
.button-text {
  font-family: 'Avenir', 'Arial';
  font-style: normal;
  letter-spacing: 1px;
}

.quote {
  font-family: 'Avenir', 'Arial';
  font-style: italic;
}

// h1 - Stor rubrik på förstasidan
// h2 - Rubrik som ligger på bilden på alla sidor utom startsidan
// h3 - Rubriker inom en sida, t.ex. "Våra lediga uppdrag" och "Registrera ditt CV"
// h4 - Rubrik i versaler, för t.ex. kurserna
// h5 - Större text, för långa rubriker/textrubriker

@media only screen and (max-width: calc(b.$md - 1px)) {
  h1 {
    font-size: 1.5em;
    font-weight: 400;
    line-height: 1.5em;
  }

  h2 {
    font-size: 38px;
    line-height: 48px;
  }

  h3 {
    font-size: 33px;
    font-weight: 400;
    line-height: 40px;
  }

  h4 {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 32px;
  }

  h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  .large-text {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
  }

  .text {
    font-size: 18px;
    line-height: 24px;
  }

  .small-text {
    font-size: 14px;
    line-height: 16px;
  }

  .link-text {
    letter-spacing: 1px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .button-text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .quote {
    font-size: 18px;
    line-height: 24px;
  }
}

@media only screen and (min-width: b.$md) {
  h1 {
    font-size: 3em;
    font-weight: 400;
    line-height: 1.2em;
  }

  h2 {
    font-size: 56px;
    font-weight: 400;
    line-height: 64px;
  }

  h3 {
    font-size: 46px;
    font-weight: 400;
    line-height: 58px;
  }

  h4 {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
  }

  h5 {
    font-size: 25px;
    font-weight: 700;
    line-height: 32px;
  }

  .large-text {
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
  }

  .text {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  .small-text {
    font-size: 14px;
    line-height: 24px;
  }

  .link-text {
    letter-spacing: 1.8px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .button-text {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .quote {
    font-size: 26px;
    line-height: 32px;
  }
}

@media only screen and (min-width: b.$xxl) {
  h1 {
    font-size: 80px;
    font-weight: 400;
    line-height: 88px;
  }

  h2 {
    font-size: 56px;
    font-weight: 400;
    line-height: 64px;
  }

  h3 {
    font-size: 46px;
    font-weight: 400;
    line-height: 58px;
  }

  h4 {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
  }

  h5 {
    font-size: 25px;
    font-weight: 700;
    line-height: 32px;
  }

  .large-text {
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
  }

  .text {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  .small-text {
    font-size: 14px;
    line-height: 24px;
  }

  .link-text {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .button-text {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .quote {
    font-size: 26px;
    line-height: 32px;
  }
}
