@use '../style/colors' as c;
@use '../style/margins' as m;

.container {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 300px;

  background-color: c.$black;
  color: c.$white;
  border-radius: 45px 0 0 45px;

  padding: m.$M6;
  transform: translateX(100%);

  .close {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: m.$M6;
  }
}

.shadow {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #00000080;
}
