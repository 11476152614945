@use '../style/colors' as c;
@use '../style/margins' as m;
@use '../style/sizes' as s;
@use '../style/breakpoints' as b;

.container {
  background-color: c.$black;
  color: c.$white;
  border-top: 1px solid c.$white;
  padding-top: m.$M9;
  padding-bottom: m.$M9;

  .logo {
    margin-left: auto;
    margin-top: auto;

    img {
      height: s.$logo-height-small;
    }
  }

  .pages,
  .contact {
    display: flex;
    flex-direction: column;
  }

  .pages {
    .link-list {
      display: flex;
      flex-direction: column;
      gap: m.$M1;

      a {
        text-decoration: none;
        color: c.$white;
      }
    }
  }

  .contact {
    .contact-list {
      display: flex;
      flex-direction: column;
      gap: m.$M3;

      div {
        display: flex;
        gap: m.$M2;
        align-items: center;
      }

      a {
        color: c.$white;
      }

      .linkedIn {
        a {
          color: c.$white;
        }
      }
    }
  }
}

@media only screen and (max-width: calc(b.$md - 1px)) {
  .container .row {
    gap: m.$M6;
  }
}
