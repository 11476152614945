@use '../style/colors' as c;

.link {
  text-decoration: none;
  color: c.$white;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.line {
  position: absolute;
  bottom: 0;
  border-top: 1px solid c.$white;
}
